<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="'/progressReport'"></headTitle>
        <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
            <van-field clickable name="picker" :value="pjMsg" label="项目信息" placeholder="点击选择项目" @click="showA = true"
                :rules="[{ required: true, message: '项目信息不能为空' }]" required />
            <van-popup v-model="showA" position="bottom">
                <van-picker show-toolbar :columns="pjOption" value-key="label" @confirm="pjConfirm"
                    @cancel="showA = false" />
            </van-popup>
            <van-field label="汇报人" :value="userMsg" readonly />

            <van-field clickable name="picker" :value="stateMsg" label="项目进度" placeholder="点击选择项目进度" @click="showC = true"
                :rules="[{ required: true, message: '项目进度不能为空' }]" required />
            <van-popup v-model="showC" position="bottom">
                <van-picker show-toolbar :columns="stateOption" value-key="text" @confirm="stateConfirm"
                    @cancel="showC = false" />
            </van-popup>
            <van-field v-model="queryFrom.description" rows="2" autosize label="进度概况" type="textarea" maxlength="800"
                placeholder="请输入进度概况" show-word-limit />

            <van-field v-model="queryFrom.problemSolutions" rows="2" autosize label="存在问题及解决方案" type="textarea"
                maxlength="800" placeholder="请输入存在问题及解决方案" show-word-limit />
            <van-field name="uploader" label="附件">
                <template #input>
                    <van-uploader v-model="uploader" @click-preview="viewFile" @delete="deleteFile" accept="file"
                        upload-icon="plus" :after-read="afterRead" :before-read="beforeRead" :preview-full-image="false" />
                </template>
            </van-field>
            <van-button round block type="info" native-type="submit" :loading="isLoading"
                loading-text="加载中...">提交</van-button>
        </van-form>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Field,
    Cell,
    Popup,
    Picker,
    Uploader,
    Button,
    Toast,
    Form
} from "vant";
import { areaList } from "../units/area";
import { infoList } from "../api/info";
import { progressAdd } from '../api/progress'

import { getToken } from "../units/auth";
import { fileUpload } from "../api/api";

Vue.use(Field);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Form)

export default {
    name: "newPjSchedule",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "新建项目进度安排",
            pjMsg: "", //项目信息显示文字
            pjOption: [], //项目选项
            userMsg: "", //用户显示文字
            stateMsg: "", //状态显示文字
            columns: [],
            queryFrom: {
                // userId: "", //汇报人
                projectId: "", //项目信息id
                projectStatus: null, //项目进度
                description: "", //进度概况
                problemSolutions: "", //问题以及解决方案
                fileList: [], //文件列表
            },
            //项目状态
            stateOption: [
                { text: "全部", value: null },
                { text: "立项审批中", value: 1 },
                { text: "立项完成", value: 2 },
                { text: "签订合同", value: 3 },
                { text: "实施中", value: 4 },
                { text: "实施完成", value: 5 },
                { text: "验收通过", value: 6 },
                { text: "收款完成", value: 7 },
                { text: "付款完成", value: 8 },
                { text: "归档完成", value: 9 },
                { text: "项目完成", value: 10 },
                { text: "项目中止", value: 11 },
                { text: "撤销", value: 12 },
            ],

            value: "",
            showA: false,
            showB: false,
            showC: false,
            fieldValue: "",
            cascaderValue: "",
            // 选项列表，children 代表子选项，支持多级嵌套
            options: areaList,
            address: "",
            ads: "",
            minDate: new Date(2022, 0, 1),
            maxDate: new Date(2027, 10, 1),
            currentDate: new Date(),
            planTime: "",
            overview: "", //进度概况
            existing: "", //存在的问题以及解决方案
            uploader: [], //上传文件
            clear: false,
            isLoading: false
        };
    },
    watch: {
        address: function (n) {
            this.ads = this.fieldValue + n;
        },
    },
    mounted() {
        this.getPjOption();
        this.getToken();
    },
    //清除文件列表
    beforeRouteLeave(to, from, next) {
        if (to.name !== "preview") {
            window.localStorage.removeItem("fileList");
            window.localStorage.removeItem("uploader");
            this.clear = true
        }
        next();
    },
    activated() {
        this.flag = JSON.parse(window.localStorage.getItem("chooseList")) || []
    },
    deactivated() {
        //实现伪注销keepalive效果
        if (this.clear) {
            this.queryFrom = {
                projectId: "",
                projectStatus: null,
                description: "",
                problemSolutions: "",
                fileList: [],
            }
            this.pjMsg = ""
            this.stateMsg = ""
            this.uploader = []
        }
    },
    methods: {
        getToken() {
            this.userMsg = getToken("userName");
        },
        getPjOption() {
            infoList().then((res) => {
                let obj = res.data.rows.map((it) => {
                    return {
                        label: it.projectName,
                        value: it.id,
                    };
                });
                this.pjOption = obj;
            });
        },

        pjConfirm(val) {
            this.showA = false;
            this.queryFrom.projectId = val.value;
            this.pjMsg = val.label;
        },
        stateConfirm(val) {
            this.showC = false;
            this.stateMsg = val.text;
            this.queryFrom.projectStatus = val.value;
        },
        onFinish({ selectedOptions }) {
            this.show = false;
            this.fieldValue = selectedOptions.map((option) => option.text).join("/");
        },
        chooseTime() {
            this.planTime = this.currentDate.toLocaleString().split(" ")[0];
            this.showB = false;
        },
        onConfirm(value) {
            this.value = value;
            this.showC = false;
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast('只允许上传30M的文件')
                return false
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            fileUpload(fd).then((res) => {
                if (res.data.code == 200) {
                    let obj = {
                        fileName: res.data.originalName,
                        url: res.data.url,
                    };
                    this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                    this.uploader[this.uploader.length - 1].fileName =
                        res.data.originalName;
                    this.queryFrom.fileList.push(obj);

                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            }).catch(err => {
                if (err) {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            });
        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter(it => { return it.url !== file.url })
        },
        viewFile(file) {
            if (file.url.indexOf('.jpg') !== -1 || file.url.indexOf('.jpeg') !== -1 || file.url.indexOf('.png') !== -1) {
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: "/newPjSchedule" },
            });
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            progressAdd(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.$router.replace('/progressReport')
                    //this.$destroy()
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
    },
};
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 2vh;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;
    font-size: 1rem;
}

/deep/ .van-cell__title {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    flex: unset;
    text-align: left;
}

/deep/ .van-cell__value {
    text-align: left;
}

.address {
    margin-bottom: unset;
}

.ads /deep/ .van-cell__value {
    border: 1px solid #c3c3c3;
}
</style>